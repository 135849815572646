import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogPost/BlogPost.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I recently went through the process of setting up a new machine: installing apps, customizing dotfiles, and `}<a parentName="p" {...{
        "href": "https://git-scm.com/docs/git-config"
      }}>{`configuring git`}</a>{`. One little nifty trick I want to highlight today is using `}<em parentName="p">{`conditional includes`}</em>{` for your global git options.`}</p>
    <h2>{`But why?`}</h2>
    <p>{`This technique could be used for various git setting, but I've primarily used it for conditionally setting the git identity.`}</p>
    <p>{`At work, we have the choice of using a dedicated or single GitHub account when working on code. When jumping between repos, I want an easy way to maintain proper commit author info by ensuring my personal and work emails where being used for the corresponding repositories.`}</p>
    <h2>{`Setting a global user and email`}</h2>
    <p>{`Git associate your identity with every commit you make. You can set or change your git identity using the `}<inlineCode parentName="p">{`git config`}</inlineCode>{` command or by saving the values in the global configuration file, `}<inlineCode parentName="p">{`~/.gitconfig`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`# ~/.gitconfig

[user]
  name = Your Name
  email = personal@website.com
`}</code></pre>
    <h2>{`Conditional extending the git configuration`}</h2>
    <p>{`The global git configuration can be extended based on the location of the repository on your file system. We can have a global configuration for all our default and then a specify overrides for all repos in a directory.`}</p>
    <p>{`If we have all work related repos in the `}<inlineCode parentName="p">{`~/work/`}</inlineCode>{` folder, we could apply our git identity overrides by adding a `}<inlineCode parentName="p">{`~/work/.gitconfig`}</inlineCode>{` and then conditionally including this in our main global config:`}</p>
    <pre><code parentName="pre" {...{}}>{`# ~/.gitconfig

[user]
  name = Your Name
  email = personal@website.com

[includeIf "gitdir:~/work/"]
  path = ~/work/.gitconfig
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`# ~/work/.gitconfig

[user]
  name = Your Name
  email = work@website.com
`}</code></pre>
    <p><strong parentName="p">{`Pro tip`}</strong>{` if you are using GitHub, checkout the `}<a parentName="p" {...{
        "href": "https://github.com/settings/notifications"
      }}>{`Settings > Notifications`}</a>{` page. Under the "Custom routing" section, you can configure notifications to different `}<a parentName="p" {...{
        "href": "https://github.com/settings/emails"
      }}>{`verified`}</a>{` email addresses based on the organization.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.567567567567565%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA1UlEQVQoz5WR246DMAxE+f+/rEQrQgVxbpiEOO04tC+rbVd75MAIeeIJGcbxuiyrMbMx98c/GZzzIQQiZ8mVQyk59/dRa8U6xQ9e5vk+w0lE3odVsdNklnVdVFosCHLeWhVWZ1BK6T3Ze5H2Z8L2LmmKPmG+jjfkxqZIp02/wTvHmFAQiIN5IUa4h7j5bWMcrO/7YWxraMj7SS65MO9qdpvxPlWp32OL1JwzM5cOUmjs6YJ/RaUcX5w7I7aCuYiNY8bUzZwybkhE2meqyNGvDQLNZ+H7E1UbDu8HzWENAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/22d3f3375d346dd0b94090ea509354cd/cbe2e/custom-routing.webp 148w", "/static/22d3f3375d346dd0b94090ea509354cd/3084c/custom-routing.webp 295w", "/static/22d3f3375d346dd0b94090ea509354cd/5ca24/custom-routing.webp 590w", "/static/22d3f3375d346dd0b94090ea509354cd/ca172/custom-routing.webp 772w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/22d3f3375d346dd0b94090ea509354cd/12f09/custom-routing.png 148w", "/static/22d3f3375d346dd0b94090ea509354cd/e4a3f/custom-routing.png 295w", "/static/22d3f3375d346dd0b94090ea509354cd/fcda8/custom-routing.png 590w", "/static/22d3f3375d346dd0b94090ea509354cd/940c5/custom-routing.png 772w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/22d3f3375d346dd0b94090ea509354cd/fcda8/custom-routing.png",
            "alt": "Custom routing section on the Settings > Notifications page",
            "title": "Custom routing section on the Settings > Notifications page",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2>{`Further reading`}</h2>
    <p>{`This is just one use-case for using the conditional includes feature in git. You can learn the details and see more examples in the git documentation `}<a parentName="p" {...{
        "href": "https://git-scm.com/docs/git-config#_conditional_includes"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      